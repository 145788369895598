// Variable overrides


.alpha { color: purple; }

$primary: #ed1c49;

$danger: #FF0000;

//$info: green;
$enable-rtl: true;