// Here you can add other styles




.mr-auto, .mx-auto {
    margin: auto !important;
}

.table tr 
{
    cursor: pointer;
}

.c-sidebar-nav-title {
     font-size: 90%;
}

.text-start {
    text-align: start !important;
    padding-top: 5px;
}


.cv-section-header {
    cursor: pointer;
    background: $primary;
    border-radius: 12px;
    color: white;
    margin-top: 15px;
}

.cv-section-header h5 {
    font-size: larger;
    padding: 15px 0px;
}

.cv-section-header h5 span{
    font-size: small;
    
}

.personal-photo-preview-text
{
    position: absolute;
    top: 30%;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 70%;       
    }

    .personal-photo-preview-text
    {
        position: inherit;

    }

}

.modal--header-color
{
    background: $primary;
    color: white;
}

.social-media-icon-label
{
    margin-bottom: 4px;
}
.custom-file-input-ext
{
    opacity: .0;
    background: $primary;
    height: 95%;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 20;
    border: 1px solid  $primary;
}
.personal-photo
{
    max-height: 210px;
    max-width: 180px;
    
}

.personal-photo-remove
{
    cursor: not-allowed;
}

.personal-photo-picker{
    position: absolute;
    top: 20px;
    height: 90%;
    width: 90%;
}

.modal-content 
{
    border-radius: 20px;
}
.modal-header
{
    border-radius: 20px 20px 0 0 ;
}

.personal-info-view input
{
    
}
.pointer
{
    cursor: pointer;
}
.cv-section-card 
{
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 5px;
   
}
.cv-section-card > div 
{
    
    box-shadow: 0 2px 3px #ccc;
    border:  1px solid #eee;
    box-sizing: border-box;
    padding-top: 10px;
}

//For multil selctor
._7ahQImyV4dj0EpcNOjnwA{
    background: $success !important;
}
.lhyQmCtWOINviMz0WG_gr{
    background: white !important;
    color: #000 !important;
}
.optionContainer li:hover{
    background: $info !important;
    color: white !important;
}
._1e56tWp4cEJMn-cD-PZArd {
    display: none !important;
}

table > tbody > tr > td > div > h2{
    display: none;
}

.actionsAlignEnd
{
    text-align: end;
}

.addTopBottomMargin
{
    margin-top: 20px ;
    margin-bottom: 20px ;
}

.hidden
{
    display: none;
}

.cv-request-details div
{
    margin-bottom: 10px;

}

.cv-table .table-responsive{
    overflow-x: unset;
}

.backup-codes-table
{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.backup-codes-table td
{
    margin: auto;
    width: 33%;
    justify-content: center;
}

.backup-codes-table td span
{   
    display:block; 
    text-align:center;
    margin:0 auto;
}

.qr-style
{
    width: 200px !important;
    height: 200px !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.searchBox {
    width: 100%;
}

.total-cvs
{
    margin-inline-start: 5px;
}

.card .c-chartjs-tooltip {
    display: none;
}

.top 
{
    left: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    border: 2px  rgb(194, 187, 187);
    border-radius: 50px;
    background-color: rgb(194, 187, 187);
    box-sizing: 10px;
}

.mainhead
{
    padding-right: 10px;
    padding-top: 3px;
    font-weight:bold;
    font-size:medium;
}